import React, { Component , Suspense } from 'react';
import { BrowserRouter,Routes, Route, Navigate } from 'react-router-dom';

const Story = React.lazy(() => import('./Components/Story'));
const Home = React.lazy(() => import('./Components/Home'));
const Games = React.lazy(() => import('./Components/Games'));
const Club = React.lazy(() => import('./Components/Club'));
const Contactus = React.lazy(() => import('./Components/Contactus'));
const Calendar = React.lazy(() => import('./Components/Calendar'));
const YaldaCalendar = React.lazy(() => import('./Components/YaldaCalendar'));
const Invoice = React.lazy(() => import('./Components/Invoice'));
const GamesLand = React.lazy(() => import('./Components/GamesLand'));
const NowruzGame = React.lazy(() => import('./Components/NowruzGame'));
const Yalda = React.lazy(() => import('./Components/Yalda'));
const History = React.lazy(() => import('./Components/History'));
const Profile = React.lazy(() => import('./Components/Profile'));
const Terms = React.lazy(() => import('./Components/Terms'));
const PaymentFailed = React.lazy(() => import('./Components/PaymentFailed'));
const YaldaStory = React.lazy(() => import('./Components/YaldaStory'));
const Yalda1402 = React.lazy(() => import('./Components/Yalda1402'));

class Site extends Component {
  constructor(props) {
    super(props);

    //برای حالتی که رندر سمت سرور داریم انجام میدم اطلاعات بایستی از این بخش به کامپوننتها تزریق بشه
    if (props.dataHolder !== null && props.dataHolder !== undefined)
    {
      this.state = {
        dataHolder:props.dataHolder,
        lang: props.dataHolder.lang
      }
    }
    else{
      var lang = 1;
      if (window != undefined && window!= null){
        //برخی چون en مستقل ندارند این شکلی نوشتم
        if (window.location.href.includes("en/") || window.location.href.includes("/en")) lang = 2;
      }
        
      this.state = {
        dataHolder:null,
        lang: lang
      }
    }
  }

  render() {
    return(
      <Suspense fallback={<div></div>}>
        <Routes>
          <Route path="/" dataHolder={this.state.dataHolder} element={<Home lang={1} />}></Route>
          <Route path="/en/" dataHolder={this.state.dataHolder} element={<Home lang={2} />}></Route>

          <Route path="/games/" dataHolder={this.state.dataHolder} element={<Games lang={1} />}></Route>
          <Route path="/games/en/" dataHolder={this.state.dataHolder} element={<Games lang={2} />}></Route>

          <Route path="/club/" dataHolder={this.state.dataHolder} element={<Club lang={1} />}></Route>
          <Route path="/club/en/" dataHolder={this.state.dataHolder} element={<Club lang={2} />}></Route>

          <Route path="/contactus/" dataHolder={this.state.dataHolder} element={<Contactus lang={1} ContactUs={false} />}></Route>
          <Route path="/contactus/en/" dataHolder={this.state.dataHolder} element={<Contactus lang={2} ContactUs={false} />}></Route>

          <Route path="/story/" dataHolder={this.state.dataHolder} element={<Story lang={1} ContactUs={false} />}></Route>
          <Route path="/story/en/" dataHolder={this.state.dataHolder} element={<Story lang={2} ContactUs={false} />}></Route>

          <Route path="/calendar/" dataHolder={this.state.dataHolder} element={<Calendar id={1} lang={1} />}></Route>
          <Route path="/calendar/en/" dataHolder={this.state.dataHolder} element={<Calendar id={1} lang={2} />}></Route>

          <Route path="/yaldacalendar/" dataHolder={this.state.dataHolder} element={<YaldaCalendar id={30} lang={1} />}></Route>
          <Route path="/yaldacalendar/en/" dataHolder={this.state.dataHolder} element={<YaldaCalendar id={30} lang={2} />}></Route>

          <Route path="/payment/failed/" dataHolder={this.state.dataHolder} element={<PaymentFailed lang={1} />}></Route>
          <Route path="/payment/faileden/" dataHolder={this.state.dataHolder} element={<PaymentFailed lang={2} />}></Route>
          
          <Route path="/invoice/:id/" dataHolder={this.state.dataHolder} element={<Invoice lang={1} />}></Route>
          <Route path="/invoiceen/:id/" dataHolder={this.state.dataHolder} element={<Invoice lang={2} />}></Route>

          <Route path="/gamesland/" dataHolder={this.state.dataHolder} element={<GamesLand lang={1} invalidSize={0} />}></Route>
          <Route path="/gamesland/en/" dataHolder={this.state.dataHolder} element={<GamesLand lang={2} invalidSize={0} />}></Route>

          <Route path="/gamesland/invalid-size/" dataHolder={this.state.dataHolder} element={<GamesLand lang={1} invalidSize={1} />}></Route>          
          <Route path="/gamesland/invalid-size/en/" dataHolder={this.state.dataHolder} element={<GamesLand lang={2} invalidSize={1} />}></Route>          

          <Route path="/nowruz/" dataHolder={this.state.dataHolder} element={<NowruzGame id={4} lang={1} />}></Route>
          <Route path="/nowruz/en/" dataHolder={this.state.dataHolder} element={<NowruzGame id={4} lang={2} />}></Route>

          <Route path="/nowruz/:game_id/" dataHolder={this.state.dataHolder} element={<NowruzGame id={4} lang={1} />}></Route>
          <Route path="/nowruz/en/:game_id/" dataHolder={this.state.dataHolder} element={<NowruzGame id={4} lang={2} />}></Route>

          <Route path="/yalda/" dataHolder={this.state.dataHolder} element={<Yalda id={31} lang={1} />}></Route>
          <Route path="/yalda/en/" dataHolder={this.state.dataHolder} element={<Yalda id={31} lang={2} />}></Route>

          <Route path="/yalda/:game_id/" dataHolder={this.state.dataHolder} element={<Yalda id={31} lang={1} />}></Route>
          <Route path="/yalda/en/:game_id/" dataHolder={this.state.dataHolder} element={<Yalda id={31} lang={2} />}></Route>

          <Route path="/history/" dataHolder={this.state.dataHolder} element={<History lang={1} ContactUs={false} />}></Route>
          <Route path="/history/en/" dataHolder={this.state.dataHolder} element={<History lang={2} ContactUs={false} />}></Route>
          
          <Route path="/profile/" dataHolder={this.state.dataHolder} element={<Profile lang={1} ContactUs={false} />}></Route>
          <Route path="/profile/en/" dataHolder={this.state.dataHolder} element={<Profile lang={2} ContactUs={false} />}></Route>
          
          <Route path="/terms/" dataHolder={this.state.dataHolder} element={<Terms lang={1} digital={false} />}></Route>
          <Route path="/terms/en/" dataHolder={this.state.dataHolder} element={<Terms lang={2} digital={false} />}></Route>

          <Route path="/terms/digital/" dataHolder={this.state.dataHolder} element={<Terms lang={1} digital={true} />}></Route>
          <Route path="/terms/digital/en/" dataHolder={this.state.dataHolder} element={<Terms lang={2} digital={true} />}></Route>
          
          <Route path="/yalda1402/" dataHolder={this.state.dataHolder} element={<Yalda1402 lang={1} />}></Route>
          <Route path="/yalda1402/en/" dataHolder={this.state.dataHolder} element={<Yalda1402 lang={2} />}></Route>

          <Route path="/yaldastory/" dataHolder={this.state.dataHolder} element={<YaldaStory lang={1} />}></Route>
          <Route path="/yaldastory/en/" dataHolder={this.state.dataHolder} element={<YaldaStory lang={2} />}></Route>

          <Route path="*" dataHolder={this.state.dataHolder} element={<Navigate to="/404/" />}></Route>           
        </Routes>
      </Suspense>
    );
  }
}
export default Site;